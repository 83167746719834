.exhibitions {
    display: flex;
    flex-direction: column;
    float: left;
    margin-right: 0.7vw;   
    pointer-events: auto;
    width: 66vw;
    min-height: 100vh;
}
.exhibitions img {
    align-self: flex-start;
    margin-bottom: 0.7vw;
    width: 66vw;
}

.exhibitions-note {
    margin-top: calc(2em + 2.7vw);
    padding-left: 0.7vw;
    padding-right: 0.7vw;
}

.line-break {
    white-space: pre-wrap;
  } 