@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400&display=swap');

/* @media all and (display-mode: fullscreen)  { */
html,
body,
#root {
  font-family: 'IBM Plex Mono', monospace;
  font-weight: 400;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  pointer-events: none;
  /* -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; */
  /* overflow: hidden; */
}

/* #root {
  overflow: auto;
} */

body {
  /* position: fixed; */
  overflow: hidden;
  /* overscroll-behavior-y: none; */
  font-family: 'IBM Plex Mono', monospace;
  /* color: black; */
  /* background-color: color(display-p3 0 0 255);; */
  background-color: #0000ff;
}

.content {
  color: white;
  font-weight: 700;
  text-align: center;
}

canvas {
  position: absolute;
  /* height: 120vh; */
  z-index:-1;
  pointer-events: auto;
  margin: 0;
  padding: 0;
  position: fixed;
  touch-action: none;
  /* width: calc(100% - 2px);  */
  /* height: calc(100% - 2px);  */
  /* border: 1px dashed; */
  top:0;
  left:0;
}