  .topcorner{
    font-weight: 700;
    float:right;
    /* position:absolute; */
    position: fixed;
    top:0;
    right:0;
    font-size: calc(2em + 2vw);
    z-index: 110;
    color: white;
    margin: 0.7vw;
    cursor: pointer;
    line-height: 76%;
    vertical-align: text-top;
    -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: auto;
   }

  .bottomcorner{
    /* font-weight: 100; */
    float:right;
    /* position:absolute; */
    position: fixed;
    bottom:0;
    right:0;
    font-size: calc(2em + 2vw);
    z-index: 110;
    color: white;
    pointer-events: auto;
   }

  .central{
    position:absolute;
    top:calc(48vh - 0.5em);
    width: 100%;
    vertical-align: middle;
    text-align: center;
    font-size: calc(2em + 2vw);
    z-index: 900;
    font-weight: 700;
    color: white;
    opacity: 0.8
   }

   .fadeIn {
    animation: fadeIn ease 0.3s;
   }

   .fadeOut {
    animation: fadeOut ease 0.3s;
    opacity: 0;
   }

   @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:0.8;}
  }

    @keyframes fadeOut {
      0% {opacity:0.8;}
      100% {opacity:0;}
      }





   @keyframes spin {
    from {
        transform: perspective(900px) rotateY(0deg) ;
      }
      to {
        transform: perspective(900px) rotateY(360deg) ;
      }
      /* 100% {
        transform: perspective(800px) rotate3d(0, 1, 0, 360deg) ;
      } */
  }