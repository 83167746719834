.flex-container {
  position: absolute;
  z-index: 2;

  color: blue;
  font-size: calc(0.6em + 0.4vw);

  padding: 10px;
  margin: 0;
  /* max-height: 98vh;  */
  max-width: 100vw;
  height: 100vh;
  width: 100vw;
  pointer-events: auto;
  overflow-y: scroll;
  /* overflow-x: hidden;  */
  -webkit-touch-callout: none;

}

.portfolio-content {
  position: absolute;
  pointer-events: auto;
  max-width: 98vw;
}

.portfolio-content>div {
  margin: 0px;
  padding: 0px;
  width: 30px;
  height: 30px;
  font-size: 30px;
  position: absolute;
  z-index: 2;
  cursor: move;
}