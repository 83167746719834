.App {
  text-align: center;
  background-color: blue;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-content {
  /* float: left; */
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  pointer-events: none;
}

.App-main {
  /* float: left; */
  /* background-color: magenta; */
  height: 100%;
  width: 100%;
  margin: 0;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.bio {
  position: relative;
  color: blue;
  font-size: calc(0.6em + 0.4vw);
  pointer-events: auto;
  padding: 0.7vw;
  margin: 0;
  max-height: 98vh;
  max-width: 100vw;
  height: 98vh;
  width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
}

.bold {
  font-weight: bold;
}

.bio-text {
  margin: 0px;
  margin-bottom: 16px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
